import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { createFetcher } from '../../libs/next/api/jsonApiHandler'
import type { API } from '../../pages/api/auth/[handler]'
import type { PlantProfile } from '../../types/PlantProfile'
import type { Credentials, SignupCredentials } from '../data/Credentials'

const api = createFetcher<API>('/api/auth/[handler]')

export function useAuth() {
	const queryClient = useQueryClient()

	const me = useQuery(['auth', 'me'], async () => {
		return api('me', {})
	})

	const signIn = useMutation(
		async (credentials: Credentials) => {
			const response = await api('signIn', credentials)
			if (response.ok === false) {
				throw new Error(response.errorMessage)
			}
		},
		{
			onSettled() {
				queryClient.invalidateQueries(['auth'])
			},
		}
	)

	const signOut = useMutation(async () => {
		return api('signOut', { _req: { method: 'POST' } }).then(() => {
			queryClient.invalidateQueries(['auth'])
		})
	})

	const signUp = useMutation(async (credentials: SignupCredentials) => {
		return api('signUp', credentials).then(() => {
			queryClient.invalidateQueries(['auth'])
		})
	})

	const likeProfile = useMutation(async (data: { profileId: string; dislike: boolean }) => {
		return api('likeProfile', data).then((data) => {
			queryClient.invalidateQueries(['auth'])
			return data
		})
	})

	const removeProfile = useMutation(async (data: { profileId: string }) => {
		return api('removeProfile', data).then((data) => {
			queryClient.invalidateQueries(['auth'])
			return data
		})
	})

	const setAvatar = useMutation(async (data: { image: File; mimeType: string }) => {
		return api('setAvatar', data).then(async (res) => {
			if (res.uploadUrl?.url) {
				const s3upload = await fetch(res.uploadUrl.url, {
					method: res.uploadUrl.method,
					body: data.image,
					headers: res.uploadUrl.headers.reduce(
						(headers, h) => ({ ...headers, [h.key]: h.value }),
						{}
					),
				})

				console.log(s3upload)
			}
			queryClient.invalidateQueries(['auth'])
		})
	})

	const createPlantProfile = useMutation(async (data: { plantProfile: PlantProfile }) => {
		return api('createProfile', {
			plantProfile: { ...data.plantProfile, thumbnail: undefined, images: [] },
			mimeType: data.plantProfile.thumbnail?.type,
			imagesMimeTypes: data.plantProfile.images.map((image) => {
				return {
					mimeType: image.type,
					isThumbnail: image.name === data.plantProfile.thumbnail?.name,
				}
			}),
		}).then(async (res) => {
			if (res.mutation.createPlantProfile.ok) {
				console.log(res.mutation.createPlantProfile.ok)
			}
			if (res.imageMutation?.generateUploadUrl.url && data.plantProfile.thumbnail) {
				const s3upload = await fetch(res.imageMutation?.generateUploadUrl.url, {
					method: res.imageMutation?.generateUploadUrl.method,
					body: data.plantProfile.thumbnail,
					headers: res.imageMutation?.generateUploadUrl.headers.reduce(
						(headers, h) => ({ ...headers, [h.key]: h.value }),
						{}
					),
				})

				console.log(s3upload)
			}
			//upload gallery images
			console.log(res.galleryImagesMutations)
			console.log(data.plantProfile.images)
			const galleryUploads = await Promise.allSettled(
				res.galleryImagesMutations.map(async (galleryImageMutation, index) => {
					if (
						galleryImageMutation &&
						galleryImageMutation.generateUploadUrl.url &&
						data.plantProfile.images[index]
					) {
						await fetch(galleryImageMutation.generateUploadUrl.url, {
							method: galleryImageMutation.generateUploadUrl.method,
							body: data.plantProfile.images[index],
							headers: galleryImageMutation.generateUploadUrl.headers.reduce(
								(headers, h) => ({ ...headers, [h.key]: h.value }),
								{}
							),
						})
					}
				})
			)
			//Check gallery uploads
			console.log(galleryUploads)
			console.log(res.mutation.createPlantProfile)
			queryClient.invalidateQueries(['auth'])
			return res.mutation.createPlantProfile
		})
	})

	const updatePlantProfile = useMutation(
		async (data: { plantProfile: PlantProfile; profileId: string }) => {
			return api('updateProfile', {
				profileId: data.profileId,
				plantProfile: { ...data.plantProfile, thumbnail: undefined, images: [] },
				mimeType: data.plantProfile.thumbnail?.type,
				imagesMimeTypes: data.plantProfile.images.map((image) => {
					return {
						mimeType: image.type,
						isThumbnail: image.name === data.plantProfile.thumbnail?.name,
					}
				}),
			}).then(async (res) => {
				if (res.mutation.updatePlantProfile.ok) {
					console.log(res.mutation.updatePlantProfile.ok)
				}
				if (res.imageMutation?.generateUploadUrl.url && data.plantProfile.thumbnail) {
					const s3upload = await fetch(res.imageMutation?.generateUploadUrl.url, {
						method: res.imageMutation?.generateUploadUrl.method,
						body: data.plantProfile.thumbnail,
						headers: res.imageMutation?.generateUploadUrl.headers.reduce(
							(headers, h) => ({ ...headers, [h.key]: h.value }),
							{}
						),
					})

					console.log(s3upload)
				}
				//upload gallery images
				console.log(res.galleryImagesMutations)
				console.log(data.plantProfile.images)
				const galleryUploads = await Promise.allSettled(
					res.galleryImagesMutations.map(async (galleryImageMutation, index) => {
						if (
							galleryImageMutation &&
							galleryImageMutation.generateUploadUrl.url &&
							data.plantProfile.images[index]
						) {
							await fetch(galleryImageMutation.generateUploadUrl.url, {
								method: galleryImageMutation.generateUploadUrl.method,
								body: data.plantProfile.images[index],
								headers: galleryImageMutation.generateUploadUrl.headers.reduce(
									(headers, h) => ({ ...headers, [h.key]: h.value }),
									{}
								),
							})
						}
					})
				)
				//Check gallery uploads
				console.log(galleryUploads)
				console.log(res.mutation.updatePlantProfile)
				queryClient.invalidateQueries(['auth'])
				return res.mutation.updatePlantProfile
			})
		}
	)

	const addComment = useMutation(
		async (data: { text: string; plantProfileId: string; image?: File; mimeType?: string }) => {
			return api('addComment', data).then(async (res) => {
				if (res.mutation.createDiscussionPost.ok) {
					console.log(res.mutation.createDiscussionPost.ok)
				}
				if (res.imageMutation?.generateUploadUrl.url) {
					const s3upload = await fetch(res.imageMutation?.generateUploadUrl.url, {
						method: res.imageMutation?.generateUploadUrl.method,
						body: data.image,
						headers: res.imageMutation?.generateUploadUrl.headers.reduce(
							(headers, h) => ({ ...headers, [h.key]: h.value }),
							{}
						),
					})

					console.log(s3upload)
				}
				console.log(res)
				queryClient.invalidateQueries(['auth'])
				return res
			})
		}
	)

	const setUserInfo = useMutation(async (data: { nick: string; email: string; bio: string }) => {
		return api('setUserInfo', data).then(async (res) => {
			if (res.updateUser.ok) {
				console.log(res.updateUser.ok)
			}
			console.log(res.updateUser)
			console.log('fail')
			queryClient.invalidateQueries(['auth'])
		})
	})

	const changePassword = useMutation(
		async (data: { currentPassword: string; newPassword: string }) => {
			return api('changePassword', data).then(async (res) => {
				if (res?.statusCode === '200') {
					return { ok: true }
				} else {
					throw new Error('Something went wrong')
				}
			})
		}
	)

	const passwordReset = useMutation(async (data: { email: string }) => {
		return api('passwordReset', data).then(async (res) => {
			if (res?.statusCode === '200') {
				return { ok: true, statusCode: res.statusCode }
			} else {
				return res
				//throw new Error(res.errorMessage)
			}
		})
	})

	return {
		me,
		signIn,
		signUp,
		signOut,
		setAvatar,
		likeProfile,
		createPlantProfile,
		setUserInfo,
		changePassword,
		addComment,
		removeProfile,
		passwordReset,
		updatePlantProfile,
	}
}

export function useAuthStatus() {
	const auth = useAuth()

	if (auth.me.isLoading) {
		return 'loading' as const
	}

	if (auth.me.isError) {
		return 'error' as const
	}

	if (auth.me.data?.status) {
		return auth.me.data.status
	}

	return 'unknown' as const
}

export function useIsSignedIn() {
	return useAuthStatus() === 'signedIn'
}

export function useIsSignedOut() {
	return useAuthStatus() === 'signedOut'
}
