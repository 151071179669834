import clsx from 'clsx'
import React from 'react'
import styles from './Container.module.sass'

export function Container(props: {
	children: React.ReactNode
	marginTop?: number
	marginBottom?: number
	view?: 'slim' | 'wide'
}) {
	return (
		<div
			className={clsx(styles.wrapper, props.view && styles[props.view])}
			style={{
				'--Container-margin-top': `${props.marginTop ?? 0}px`,
				marginBottom: `${props.marginTop ?? 0}px`,
			}}>
			{props.children}
		</div>
	)
}
