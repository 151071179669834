/* eslint-disable @next/next/no-html-link-for-pages */
import clsx from 'clsx'
import Image from 'next/legacy/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import type { UserResult } from '../data/fragments/UserFragment'
import { usePlanteeUser } from '../hooks/usePlanteeUser'
import styles from './NavUser.module.sass'

export function NavUser(props: { user: UserResult }) {
	const plantee = usePlanteeUser()

	const [menuOpen, setMenuOpen] = React.useState<boolean>(false)

	const router = useRouter()

	const locale = router.locale

	return (
		<div className={styles.wrapper}>
			<div className={styles.inner}>
				<button
					className={clsx(styles.button, menuOpen && styles.menuOpen)}
					onClick={() => {
						setMenuOpen((old) => !old)
					}}>
					<div className={styles.burger}>
						<span></span>
						<span></span>
						<span></span>
					</div>
					<div className={styles.image}>
						{props.user?.avatar && (
							<Image
								key={String(props.user?.avatar?.id)}
								src={props.user?.avatar?.url}
								layout="fill"
								objectFit="cover"
								alt={props.user?.avatar.alt ?? props.user?.avatar.description ?? ''}
								placeholder="blur"
								blurDataURL={props.user?.avatar.url}
							/>
						)}
					</div>
				</button>
				<ul className={clsx(styles.menu, menuOpen && styles.active)}>
					<li>
						<a href="/create">
							{locale === 'cs' ? 'Vytvořit nový pěstební profil' : 'Create a new plant profile'}
						</a>
					</li>
					<li>
						<Link href={`/user/${props.user?.id}`}>
							{locale === 'cs' ? 'Můj profil' : 'My profile'}
						</Link>
					</li>
					<li>
						<Link href={`/user/edit`}>
							{locale === 'cs' ? 'Nastavení profilu' : 'Edit profile'}
						</Link>
					</li>
					<li>
						<form
							action=""
							method="POST"
							onSubmit={(e) => {
								e.preventDefault()
								plantee.auth.signOut.mutate()
								router.push('/')
							}}>
							<fieldset
								disabled={plantee.auth.signOut.isLoading}
								style={{ opacity: plantee.auth.signOut.isLoading ? 0.5 : 1 }}>
								<button type="submit">{locale === 'cs' ? 'Odhlásit se' : 'Log out'}</button>
							</fieldset>
						</form>
					</li>
				</ul>
			</div>
		</div>
	)
}
