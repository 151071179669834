import clsx from 'clsx'
import { ButtonBase, DistinctBaseButtonProps, SharedBaseButtonProps } from 'nextjs-button-base'
import type { FunctionComponent, ReactNode } from 'react'
import styles from './Button.module.sass'
import { Icon, IconName } from './Icon'

export type ButtonProps = {
	size?: 1 | 2 | 3
	fill?: 'empty' | 'green100' | 'green600' | 'greenButton' | 'grey100' | 'groundBlack' | 'red600'
	fontWeight?: 'default' | '400' // default = 700
	fontSize?: 'default' | 'small' // default = 700
	isBorder?: boolean
	isUpperCase?: boolean
	icon?: ButtonIconProps
} & Omit<SharedBaseButtonProps, 'className'> &
	DistinctBaseButtonProps

type ButtonIconProps = {
	name: IconName
	position?: 'start' | 'end'
}

export const Button: FunctionComponent<ButtonProps> = ({
	size = 1,
	fill = 'empty',
	fontWeight = 'default',
	fontSize = 'default',
	isBorder = false,
	isUpperCase = false,
	icon,
	...otherProps
}) => {
	return (
		<ButtonBase
			{...otherProps}
			className={clsx(
				styles.wrapper,
				styles[`is_size_${size}`],
				styles[`is_fill_${fill}`],
				styles[`is_fontWeight_${fontWeight}`],
				styles[`is_fontSize_${fontSize}`],
				isBorder && styles.is_border,
				isUpperCase && styles.is_upperCase
			)}>
			<ButtonContent icon={icon}>{otherProps.children}</ButtonContent>
		</ButtonBase>
	)
}

type ButtonContentProps = {
	children: ReactNode
	icon: ButtonProps['icon']
}

const ButtonContent: FunctionComponent<ButtonContentProps> = ({ children, icon }) => {
	const iconName = icon?.name
	const iconPosition = icon?.position

	return (
		<span className={clsx(styles.content, styles[`is_icon_${iconPosition}`])}>
			{iconName && (
				<span className={styles.icon}>
					<Icon name={iconName} />
				</span>
			)}
			{children}
		</span>
	)
}
