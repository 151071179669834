export function SearchIcon() {
	return (
		<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="16.3456" cy="16.331" r="8.09171" stroke="#5A5A5A" strokeWidth="2" />
			<path
				d="M28.4834 28.4687L24.4375 24.4229"
				stroke="#5A5A5A"
				strokeWidth="2"
				strokeLinecap="square"
			/>
		</svg>
	)
}
