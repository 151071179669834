import Link from 'next/link'
import { useRouter } from 'next/router'
import { useIsSignedIn, useIsSignedOut } from '../hooks/useAuth'
import useMediaQuery from '../hooks/useMediaQuery'
import { usePlanteeUser } from '../hooks/usePlanteeUser'
import styles from '../styles/Nav.module.sass'
import { Logo } from './Logo.svg'
import { NavUser } from './NavUser'
import { Search } from './Search'

function PrimaryNavItems() {
	return (
		<div className={styles.primaryNavItems}>
			{/* <div className={styles.appLink}>
				<PlanteeAppLink />
			</div> */}
		</div>
	)
}

export function Nav() {
	const locale = useRouter().locale
	const plantee = usePlanteeUser()

	const signedIn = useIsSignedIn()
	const signedOut = useIsSignedOut()

	const user = plantee.auth.me.data?.user

	const isMobile = useMediaQuery('(max-width: 900px)')

	return (
		<nav className={styles.wrapper}>
			<div className={styles.inner}>
				<Link href="/" className={styles.logo}>
					<Logo />
					<span>base</span>
				</Link>
				{!isMobile && (
					<>
						<div className={styles.primaryNavItems}>
							<PrimaryNavItems />
						</div>
						<Search />
					</>
				)}
				{!!(signedIn && user) && <NavUser user={user} />}
				{signedOut && (
					<div className={styles.user}>
						<Link href="/user/edit">{locale === 'cs' ? 'Přihlásit se' : 'Log in'}</Link>
					</div>
				)}
			</div>
		</nav>
	)
}
