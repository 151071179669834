import Link from 'next/link'
import { Container } from './Container'
import styles from './Footer.module.sass'
import { Logo } from './Logo.svg'
import { PlanteeAppLink } from './PlanteeAppLink'

export function Footer() {
	return (
		<footer className={styles.wrapper}>
			<Container view="wide">
				<div className={styles.inner}>
					<div className={styles.left}>
						<Link href="/" className={styles.logo}>
							<Logo />
						</Link>
						<small className={styles.copy}>
							{`© ${new Date().getFullYear()} Plantee Innovations s.r.o.`}
						</small>
					</div>
					<div className={styles.right}>
						<PlanteeAppLink />
					</div>
				</div>
			</Container>
		</footer>
	)
}
