import { useQuery } from '@tanstack/react-query'
import clsx from 'clsx'
import Image from 'next/legacy/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import { useSearch } from '../utils/SearchContext'
import { usePageLoadingState } from '../utils/usePageLoadingState'
import styles from './Search.module.sass'
import { SearchIcon } from './SearchIcon.svg'

export function Search(props: { isMobile?: boolean }) {
	const search = useSearch()

	const locale = useRouter().locale

	const searchInput = React.useRef(null)

	const [loading] = usePageLoadingState()

	React.useEffect(() => {
		if (loading) {
			search.setQuery('')
		}
	}, [loading, search])

	const result = useQuery(
		['search', search.query] as const,
		async (context) => {
			const q = context.queryKey[1]
			if (q) {
				const result = await search.search(q)
				return {
					plantProfiles: result.plantProfiles.listPlantProfileLocale,
				}
			}
			return null
		},
		{ keepPreviousData: true }
	)

	const results = result.data

	return (
		<div className={clsx(styles.wrapper, props.isMobile && styles.viewMobile)}>
			<div className={clsx(styles.container)}>
				<div className={clsx(styles.search, props.isMobile && styles.viewMobile)}>
					<input
						type="search"
						placeholder={locale === 'cs' ? 'Hledat' : 'Search'}
						ref={searchInput}
						onChange={async (e) => {
							const query = e.target.value
							search.setQuery(query)
						}}></input>
					<button className={styles.searchButton}>
						<SearchIcon />
					</button>
				</div>
				{results?.plantProfiles && results?.plantProfiles.length > 0 && (
					<ul className={styles.results}>
						{results?.plantProfiles.map((item) => {
							return (
								<li key={String(item.root?.id)}>
									<Link
										href={`/profile/${item.root?.activeInPlantProfile?.author?.id}/${item.root?.activeInPlantProfile?.id}`}
										className={styles.searchItem}>
										<div className={styles.searchItemImage}>
											{item.root?.thumbnail && (
												<Image
													src={item.root?.thumbnail?.url}
													layout="fill"
													objectFit="cover"
													alt={item.root.thumbnail.alt ?? item.root.thumbnail.description ?? ''}
													placeholder="blur"
													blurDataURL={item.root.thumbnail.url}
												/>
											)}
										</div>
										<h3>{item.name}</h3>
									</Link>
								</li>
							)
						})}
					</ul>
				)}
			</div>
		</div>
	)
}
