import { useMutation } from '@tanstack/react-query'
import { useAuth } from './useAuth'

export function usePlanteeUser() {
	const auth = useAuth()

	const changeNick = useMutation(async (patch: { nick: string }) => {
		alert(`Changed nick to ${patch.nick}`)
	})

	return { auth, changeNick }
}
